<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate" [attr.aria-label]="'APP.INFO_DRAWER.DATA_LOADING' | translate"></mat-progress-bar>
</div>
<ng-container *ngIf="!isLoading && !!displayNode">
  <adf-info-drawer [title]="'APP.INFO_DRAWER.TITLE'" cdkTrapFocus cdkTrapFocusAutoCapture>
    <aca-toolbar [items]="actions" info-drawer-buttons></aca-toolbar>

    <adf-info-drawer-tab *ngFor="let tab of tabs" [icon]="tab.icon" [label]="tab.title">
      <adf-dynamic-tab [node]="$any(displayNode)" [id]="tab.component" [attr.data-automation-id]="tab.component"> </adf-dynamic-tab>
    </adf-info-drawer-tab>
  </adf-info-drawer>
</ng-container>
